.contactFormContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    text-align: center;
}

.contactFormOptionsList {
    width: 100%;
    border-radius: 9px;
    padding: 5px;
    overflow: hidden;
    background-color: white;
}

.notes {
    min-height: 100px;
}

.notes textarea {
    padding: 5px 5px 5px 20px;
    border: none;
    flex-grow: 1;
}

.option {
    display: flex;
    flex-direction: row;
}

.option input {
    border: none;
    font-size: 1rem;
    width: 35%;
    padding-left: 20px;
    flex-grow: 1;
}

.option select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0px;
    text-overflow: '';
    padding-right: 3px;
    border: none;
    font-size: 1rem;
    width: 30%;
    background: white;
    color: #1a1a1a;
    direction: ltr;
    flex-grow: 1;
}

.option select:active {
    direction: ltr;
}

.option label {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0px;
    text-overflow: '';
    border: none;
    background: white;
    color: #1a1a1a;
    direction: ltr;
    margin: 0;
    padding: 5px;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    min-width: 50px;
}

.option textarea {
    direction: ltr;
    margin: 0;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    min-height: 100px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

hr {
    width: unset;
    margin: 0px 5px;
    color: #f1f0f5;
    border: solid 1px;
    margin: 5px;
}

.title {
    text-align: center;
    padding: 20px;
}

.submitButton {
    align-self: center;
    margin: 30px 0px 0px;
    width: 100%;
}

.addressContainer {
    display: flex;
    flex-direction: row;
    align-self: center;
}

.address {
    padding: 0 0 10px;
    font-size: 1rem;
}

.preSendHTML {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-content: center;
}

.explination {
    font-size: 0.8rem;
}