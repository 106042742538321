.backdrop {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    opacity: 0;
    transition: opacity 0.8s;
}

.modal {
    position: fixed;
    width: 90vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    top: 0;
    margin-top: 80px;
    padding: 15px;
    border-radius: 20px;
    max-width: 400px;
    background-color: #f1f0f5;
    transition: opacity 0.8s;
    transition: all 0.8s;
    opacity: 0;
}

.enable {
    display: flex;
    flex-direction: column;
    opacity: 1;
    transition: opacity 0.8s;
}

.close {
    position: absolute;
    right: 0;
    margin-right: 20px;
    font-size: 1rem;
    cursor: pointer;
}

.fadeIn {
    animation-name: fadeInAnimation;
    animation-duration: 0.8s;
    transition: 0.8s;
    opacity: 1;
}

@keyframes fadeInAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}