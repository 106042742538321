.option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.option input {
    text-align: right;
    border: none;
    font-size: 1rem;
    width: 35%;
}

.option select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0px;
    text-overflow: '';
    padding-right: 3px;
    border: none;
    font-size: 1rem;
    width: 30%;
    background: white;
    color: #1a1a1a;
    direction: rtl;
}

.option select:active {
    direction: ltr;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}