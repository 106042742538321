h1 {
    font-size: 32px;
    margin: 10px auto auto;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}

h2 {
    font-size: 18px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
}

h3 {
    margin: 0;
    padding: 5px;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
}

h4 {
    margin: 5px 0 0;
    color: #87868b;
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
}

h5 {
    font-size: 11px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    margin: 0;
    text-align: left;
    color: #87868b;
    padding: 10px;
}

h6 {
    margin: 0;
    color: #87868b;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: lighter;
}

.center {
    display: flex;
    flex-direction: column;
    /* margin: auto; */
    text-align: center;
}

.quoteDetails {
    align-self: center;
    padding: 10px;
    margin: 15px 0px;
    max-width: 500px;
}

.hrBreak {
    width: 90vw;
    border: solid 1px #3c475250;
    margin: 45px 10px 5px;
    max-width: 500px;
    align-self: center;
}