/* Media Query */
/* Taller footer on small screens */
@media (max-width: 500px) {
    #viewDiv {}
}

/* Map Dimentions */
/* ‾‾‾‾‾‾‾‾‾‾‾‾‾‾ */
.viewDiv {
    display: flex;
    flex-direction: column-reverse;
    box-sizing: border-box;
    height: 100%;
    transition: opacity 0.8s;
}

#mapContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    height: 100%;
    /* top: -5px; */
    position: inherit;
    z-index: 0;
}

/* Search */
/* ‾‾‾‾‾‾ */
.esri-input {
    /* color: grey !important; */
    background-color: white;
    border-radius: 0px !important;
    text-align: center;
}

#searchContainer {
    position: absolute;
    top: 10vh;
    z-index: 3;
    align-self: center;
    width: 240px;
}

.esri-search__form {
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 0px 10px #cfcfcf;
    border: solid 1px grey;
}

.esri-search__form input {
    background-color: #f1f0f5;
}

.esri-search__submit-button {
    border: none !important;
}

/* Media Queries */
/* ‾‾‾‾‾‾‾‾‾‾‾‾‾ */
@media screen and (min-width: 600px) {
    #viewDiv {
        height: 400px;
    }

    #expandMap_button {
        display: none;
    }
}

.esri-view-surface--touch-none {
    outline: none !important;
}

/*  FUTURE Mdeia Query (Map Search Icon) */
.esri-search__submit-button {
    display: none !important;
}

.esri-search__clear-button {
    display: none !important;
}

.esri-view .esri-view-surface--inset-outline:focus::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    outline: auto 2px;
    /* outline: auto 5px -webkit-focus-ring-color; */
    /* <- This is so annoying */
    outline: none;
    outline-offset: -3px;
    pointer-events: none;
    overflow: hidden;
}

#mapOverlay {
    z-index: 1;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-self: center;
    padding: 15vh 20px 0px;
    opacity: 1;
    transition: all 0.8s;
    justify-content: space-between;
    height: 100%;
}

.disableMap {
    user-select: none !important;
    pointer-events: none !important;
    /* opacity: 0.5 !important;
    transition: opacity 0.5s; */
}

.mapProcessingModal {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #18222d;
    opacity: 0;
    transition: opacity 0.5s;
}

.pointTwoOpacity {
    opacity: 0.2;
    transition: opacity 0.5s;
}

#confirmLocation__container {
    z-index: 3;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-self: center;
    bottom: 10vh;
    width: 100vw;
    max-width: 450px;
}

.backgroundBlur {
    backdrop-filter: blur(4px);
}

.requestHelp {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-bottom: 20px;
}

.viewDivContainer {
    width: 100%;
    height: 100%;
    transition: opacity 0.8s;
    /* padding-bottom: 15px; */
    /* For Attribution */
}

.esri-attribution {
    position: absolute;
    width: 100vw;
    display: flex;
    flex-direction: row;
    margin: auto;
    background-color: transparent !important;
    opacity: 0.5;
}

.fadeIn {
    animation-name: fadeInAnimation !important;
    animation-duration: 0.8s !important;
    transition: 0.8s !important;
    opacity: 1 !important;
}

.searchHelperInformation {
    padding: 0px 20px;
    text-align: center;
}

.searchUI_elements {
    display: flex;
    flex-direction: column;
    max-width: 350px;
}

@keyframes fadeInAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}