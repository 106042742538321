.titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 10px;
    margin: 0;
}

.optionsList {
    border-radius: 9px;
    padding: 5px;
    overflow: hidden;
    background-color: white;
}

hr {
    width: unset;
    margin: 0px 5px;
    color: #f1f0f5;
    border: solid 1px;
    margin: 5px;
}