.masterContainer {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    text-align: center;
    padding: 10px;
    color: #3c4752;
    max-width: 500px;
}

.group {
    display: flex;
    flex-direction: column;
    margin: 30px 0px;
    align-items: center;
    font-size: 0.8rem;
}

.bigTitle {
    padding: 80px 0 50px;
    font-family: lobster, helvetica, arial;
    font-size: 3rem;
    color: #3c4752;
}

.fontAwesomeIcon {
    font-size: 2rem;
    margin-bottom: 5px;
}

.logosContainer {
    display: flex;
    flex-wrap: wrap;
    width: 75vw;
    max-width: 700px;
    justify-content: space-around;
    gap: 10px;
}

.logosContainer img {
    height: 40px;
    width: auto;
    margin-bottom: 25px;
}

.multipleComapniesTitle {
    font-size: 1.2rem;
    margin: 20px 0 10px;
}

.hrBreak {
    width: 100%;
    border: solid 1px #3c475250;
    margin-top: 35px;
}