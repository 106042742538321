.detailsContainer {
    padding: 50px 10px 40px;
    text-align: center;
}

.title {
    padding: 10px 0px;
}

.details {
    padding: 0px 10px;
}