.container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    min-height: -webkit-fill-available;
    padding: 0;
    margin: 0;
    overflow: hidden;
    background-color: #18222d;
    /* background-color: #a8c5ff; */
    border-bottom: solid 5px #e6e7e8;
    box-sizing: content-box;
}

.top {
    height: 30%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.bottom {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 150%;
    height: 110%;
    background-color: white;
    border-radius: 100% 100% 0 0;
    top: -10%;
    left: -25%;
    right: 0;
    justify-content: center;
    overflow: hidden;
    z-index: 2;
}

.bottomContainer {
    /* height: 72vh; */
    flex: 1;
}

.bottomContainerOverlay {
    position: absolute;
    width: 100%;
    margin: auto;
    margin-top: 10vh;
    padding: 5px;
    display: flex;
    flex-direction: column;
    z-index: 3;
}

.title {
    text-align: center;
    margin-top: 7vh;
    font-family: Lobster, Arial, Helvetica, sans-serif;
    color: #eff0f1;
    font-weight: normal;
    font-size: 2rem;
}

.parsedAddress__street {
    text-align: center;
    margin-top: 10%;
    /* font-family: Lobster, Arial, Helvetica, sans-serif; */
}

.parsedAddress__verification {
    display: flex;
    color: #eff0f1;
    flex-direction: column;
    text-align: center;
    position: relative;
    top: 25%;
    transition: all 0.5s;
}

.parsedAddress__city {
    text-align: center;
    margin-top: 10%;
    /* font-family: Lobster, Arial, Helvetica, sans-serif; */
}