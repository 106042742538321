@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster);
* {
  box-sizing: border-box;
}

html {
  font-family: 'Noto Sans JP', sans-serif;
}

body {
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  color: #4D4D4D;
  font-size: 68%;
  padding: none;
  background-color: #f1f0f5;
  color: #1a1a1a;
}

#backdrop-root {
  z-index: 10;
  display: flex;
  justify-content: center;
}

#root {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

button {
  padding: 15px 40px;
  margin: 10px 0px 30px;
  border: none;
  border-radius: 8px;
  background-color: #18222d;
  font-size: 15px;
  color: white;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.5s;
}

button:disabled {
  opacity: 0.5;
  transition: opacity 0.5s;
  pointer-events: none;
  cursor: default;
}

.editButton {
  padding: 0;
  color: #007aff;
  font-size: 15px;
  margin-bottom: 5px;
  cursor: pointer;
}

.zeroOpacity {
  opacity: 0;
  transition: opacity 2s;
}

.remove {
  -webkit-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
  pointer-events: none ! important;
  display: none !important;
}

.primaryButton {
  margin-bottom: 20px;
  background-color: #18222d;
  color: white;
  transition: all 0.2s;
}

.primaryButton:hover {
  box-shadow: inset 0px 0px 0px 2px #18222d;
  background-color: transparent;
  color: #18222d;
}

.secondaryButton {
  box-shadow: inset 0px 0px 0px 2px #18222d;
  border-radius: 5px;
  background-color: transparent;
  color: #18222d;
  transition: all 0.2s;
}

.secondaryButton:hover {
  background-color: #676e75;
  color: white;
  box-shadow: none;
}
.option_option__2iMGp {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.option_option__2iMGp input {
    text-align: right;
    border: none;
    font-size: 1rem;
    width: 35%;
}

.option_option__2iMGp select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0px;
    text-overflow: '';
    padding-right: 3px;
    border: none;
    font-size: 1rem;
    width: 30%;
    background: white;
    color: #1a1a1a;
    direction: rtl;
}

.option_option__2iMGp select:active {
    direction: ltr;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
input.enableEdit {
    color: #007aff;
}

input.disableEdit {
    color: #1a1a1a;
    background: white;
    pointer-events: none;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

select.enableEdit {
    color: #007aff;
}

select.disableEdit {
    color: #1a1a1a;
    pointer-events: none;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background: white;
}
.optionsList_titleContainer__1vdEw {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 10px;
    margin: 0;
}

.optionsList_optionsList__1k9BW {
    border-radius: 9px;
    padding: 5px;
    overflow: hidden;
    background-color: white;
}

hr {
    width: unset;
    margin: 0px 5px;
    color: #f1f0f5;
    border: solid 1px;
    margin: 5px;
}
h1 {
    font-size: 32px;
    margin: 10px auto auto;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}

h2 {
    font-size: 18px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
}

h3 {
    margin: 0;
    padding: 5px;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
}

h4 {
    margin: 5px 0 0;
    color: #87868b;
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
}

h5 {
    font-size: 11px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    margin: 0;
    text-align: left;
    color: #87868b;
    padding: 10px;
}

h6 {
    margin: 0;
    color: #87868b;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: lighter;
}

.quoteDetails_center__19unY {
    display: flex;
    flex-direction: column;
    /* margin: auto; */
    text-align: center;
}

.quoteDetails_quoteDetails__1WzxZ {
    align-self: center;
    padding: 10px;
    margin: 15px 0px;
    max-width: 500px;
}

.quoteDetails_hrBreak__1rzlS {
    width: 90vw;
    border: solid 1px #3c475250;
    margin: 45px 10px 5px;
    max-width: 500px;
    align-self: center;
}
.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.loader_ldsGridParent__2ofnC {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
    z-index: 999;
    -webkit-animation: loader_fadeIn__2lz4h;
            animation: loader_fadeIn__2lz4h;
}

.loader_ldsGrid__18Via {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.loader_ldsGrid__18Via div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    /* background: #fff; */
    background: #18222d;
    -webkit-animation: loader_ldsGrid__18Via 1.2s linear infinite;
            animation: loader_ldsGrid__18Via 1.2s linear infinite;
}

.loader_ldsGrid__18Via div:nth-child(1) {
    top: 8px;
    left: 8px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
}

.loader_ldsGrid__18Via div:nth-child(2) {
    top: 8px;
    left: 32px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
}

.loader_ldsGrid__18Via div:nth-child(3) {
    top: 8px;
    left: 56px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
}

.loader_ldsGrid__18Via div:nth-child(4) {
    top: 32px;
    left: 8px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
}

.loader_ldsGrid__18Via div:nth-child(5) {
    top: 32px;
    left: 32px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
}

.loader_ldsGrid__18Via div:nth-child(6) {
    top: 32px;
    left: 56px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
}

.loader_ldsGrid__18Via div:nth-child(7) {
    top: 56px;
    left: 8px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
}

.loader_ldsGrid__18Via div:nth-child(8) {
    top: 56px;
    left: 32px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
}

.loader_ldsGrid__18Via div:nth-child(9) {
    top: 56px;
    left: 56px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
}

@-webkit-keyframes loader_ldsGrid__18Via {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}

@keyframes loader_ldsGrid__18Via {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}
/* Media Query */
/* Taller footer on small screens */
@media (max-width: 500px) {
    #viewDiv {}
}

/* Map Dimentions */
/* ‾‾‾‾‾‾‾‾‾‾‾‾‾‾ */
.viewDiv {
    display: flex;
    flex-direction: column-reverse;
    box-sizing: border-box;
    height: 100%;
    transition: opacity 0.8s;
}

#mapContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    height: 100%;
    /* top: -5px; */
    position: inherit;
    z-index: 0;
}

/* Search */
/* ‾‾‾‾‾‾ */
.esri-input {
    /* color: grey !important; */
    background-color: white;
    border-radius: 0px !important;
    text-align: center;
}

#searchContainer {
    position: absolute;
    top: 10vh;
    z-index: 3;
    align-self: center;
    width: 240px;
}

.esri-search__form {
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 0px 10px #cfcfcf;
    border: solid 1px grey;
}

.esri-search__form input {
    background-color: #f1f0f5;
}

.esri-search__submit-button {
    border: none !important;
}

/* Media Queries */
/* ‾‾‾‾‾‾‾‾‾‾‾‾‾ */
@media screen and (min-width: 600px) {
    #viewDiv {
        height: 400px;
    }

    #expandMap_button {
        display: none;
    }
}

.esri-view-surface--touch-none {
    outline: none !important;
}

/*  FUTURE Mdeia Query (Map Search Icon) */
.esri-search__submit-button {
    display: none !important;
}

.esri-search__clear-button {
    display: none !important;
}

.esri-view .esri-view-surface--inset-outline:focus::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    outline: auto 2px;
    /* outline: auto 5px -webkit-focus-ring-color; */
    /* <- This is so annoying */
    outline: none;
    outline-offset: -3px;
    pointer-events: none;
    overflow: hidden;
}

#mapOverlay {
    z-index: 1;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-self: center;
    padding: 15vh 20px 0px;
    opacity: 1;
    transition: all 0.8s;
    justify-content: space-between;
    height: 100%;
}

.disableMap {
    -webkit-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
    pointer-events: none !important;
    /* opacity: 0.5 !important;
    transition: opacity 0.5s; */
}

.mapProcessingModal {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #18222d;
    opacity: 0;
    transition: opacity 0.5s;
}

.pointTwoOpacity {
    opacity: 0.2;
    transition: opacity 0.5s;
}

#confirmLocation__container {
    z-index: 3;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-self: center;
    bottom: 10vh;
    width: 100vw;
    max-width: 450px;
}

.backgroundBlur {
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
}

.requestHelp {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-bottom: 20px;
}

.viewDivContainer {
    width: 100%;
    height: 100%;
    transition: opacity 0.8s;
    /* padding-bottom: 15px; */
    /* For Attribution */
}

.esri-attribution {
    position: absolute;
    width: 100vw;
    display: flex;
    flex-direction: row;
    margin: auto;
    background-color: transparent !important;
    opacity: 0.5;
}

.fadeIn {
    -webkit-animation-name: fadeInAnimation !important;
            animation-name: fadeInAnimation !important;
    -webkit-animation-duration: 0.8s !important;
            animation-duration: 0.8s !important;
    transition: 0.8s !important;
    opacity: 1 !important;
}

.searchHelperInformation {
    padding: 0px 20px;
    text-align: center;
}

.searchUI_elements {
    display: flex;
    flex-direction: column;
    max-width: 350px;
}

@-webkit-keyframes fadeInAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeInAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
.propertySearch_container__1lkcV {
    height: 100vh;
    display: flex;
    flex-direction: column;
    min-height: -webkit-fill-available;
    padding: 0;
    margin: 0;
    overflow: hidden;
    background-color: #18222d;
    /* background-color: #a8c5ff; */
    border-bottom: solid 5px #e6e7e8;
    box-sizing: content-box;
}

.propertySearch_top__2CjtB {
    height: 30%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.propertySearch_bottom__sAhzc {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 150%;
    height: 110%;
    background-color: white;
    border-radius: 100% 100% 0 0;
    top: -10%;
    left: -25%;
    right: 0;
    justify-content: center;
    overflow: hidden;
    z-index: 2;
}

.propertySearch_bottomContainer__3Xm-z {
    /* height: 72vh; */
    flex: 1 1;
}

.propertySearch_bottomContainerOverlay__3y_eG {
    position: absolute;
    width: 100%;
    margin: auto;
    margin-top: 10vh;
    padding: 5px;
    display: flex;
    flex-direction: column;
    z-index: 3;
}

.propertySearch_title__2zRv4 {
    text-align: center;
    margin-top: 7vh;
    font-family: Lobster, Arial, Helvetica, sans-serif;
    color: #eff0f1;
    font-weight: normal;
    font-size: 2rem;
}

.propertySearch_parsedAddress__street__2SEZh {
    text-align: center;
    margin-top: 10%;
    /* font-family: Lobster, Arial, Helvetica, sans-serif; */
}

.propertySearch_parsedAddress__verification__3d90s {
    display: flex;
    color: #eff0f1;
    flex-direction: column;
    text-align: center;
    position: relative;
    top: 25%;
    transition: all 0.5s;
}

.propertySearch_parsedAddress__city__Dd5Mc {
    text-align: center;
    margin-top: 10%;
    /* font-family: Lobster, Arial, Helvetica, sans-serif; */
}
.programInformation_masterContainer__1Vx-e {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    text-align: center;
    padding: 10px;
    color: #3c4752;
    max-width: 500px;
}

.programInformation_group__3SDGO {
    display: flex;
    flex-direction: column;
    margin: 30px 0px;
    align-items: center;
    font-size: 0.8rem;
}

.programInformation_bigTitle__30Snf {
    padding: 80px 0 50px;
    font-family: lobster, helvetica, arial;
    font-size: 3rem;
    color: #3c4752;
}

.programInformation_fontAwesomeIcon__2TlSW {
    font-size: 2rem;
    margin-bottom: 5px;
}

.programInformation_logosContainer__2iZtJ {
    display: flex;
    flex-wrap: wrap;
    width: 75vw;
    max-width: 700px;
    justify-content: space-around;
    grid-gap: 10px;
    gap: 10px;
}

.programInformation_logosContainer__2iZtJ img {
    height: 40px;
    width: auto;
    margin-bottom: 25px;
}

.programInformation_multipleComapniesTitle__1rJiw {
    font-size: 1.2rem;
    margin: 20px 0 10px;
}

.programInformation_hrBreak__4eI87 {
    width: 100%;
    border: solid 1px #3c475250;
    margin-top: 35px;
}
.development_developmentText__3f4rf {
    display: flex;
    align-self: center;
    text-align: center;
    position: fixed;
    z-index: 100;
    color: darkorange;
    font-weight: bolder;
    padding: 2px 4px;
    cursor: default;
    pointer-events: none;
    background-color: #18222dd1;
    border-radius: 0px 0px 5px 5px;
}
.footer_masterContainer__3OOwJ {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: flex-end;
    margin-top: 80px;
    padding: 40px 40px 20px;
    min-height: 100px;
    background-color: #18222d;
    color: #f1f0f5;
}
.requestInformation_contactFormContainer__1kL-5 {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    text-align: center;
}

.requestInformation_contactFormOptionsList__35LkO {
    width: 100%;
    border-radius: 9px;
    padding: 5px;
    overflow: hidden;
    background-color: white;
}

.requestInformation_notes__1adOB {
    min-height: 100px;
}

.requestInformation_notes__1adOB textarea {
    padding: 5px 5px 5px 20px;
    border: none;
    flex-grow: 1;
}

.requestInformation_option__3nDCb {
    display: flex;
    flex-direction: row;
}

.requestInformation_option__3nDCb input {
    border: none;
    font-size: 1rem;
    width: 35%;
    padding-left: 20px;
    flex-grow: 1;
}

.requestInformation_option__3nDCb select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0px;
    text-overflow: '';
    padding-right: 3px;
    border: none;
    font-size: 1rem;
    width: 30%;
    background: white;
    color: #1a1a1a;
    direction: ltr;
    flex-grow: 1;
}

.requestInformation_option__3nDCb select:active {
    direction: ltr;
}

.requestInformation_option__3nDCb label {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0px;
    text-overflow: '';
    border: none;
    background: white;
    color: #1a1a1a;
    direction: ltr;
    margin: 0;
    padding: 5px;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    min-width: 50px;
}

.requestInformation_option__3nDCb textarea {
    direction: ltr;
    margin: 0;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    min-height: 100px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

hr {
    width: unset;
    margin: 0px 5px;
    color: #f1f0f5;
    border: solid 1px;
    margin: 5px;
}

.requestInformation_title__1nXbp {
    text-align: center;
    padding: 20px;
}

.requestInformation_submitButton__3IsKz {
    align-self: center;
    margin: 30px 0px 0px;
    width: 100%;
}

.requestInformation_addressContainer__2rd-z {
    display: flex;
    flex-direction: row;
    align-self: center;
}

.requestInformation_address__2Mt5o {
    padding: 0 0 10px;
    font-size: 1rem;
}

.requestInformation_preSendHTML__1oPvv {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-content: center;
}

.requestInformation_explination__3xqtW {
    font-size: 0.8rem;
}
.requestAssistance_contactFormContainer__25zZG {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    text-align: center;
}

.requestAssistance_contactFormOptionsList__18WVL {
    width: 100%;
    border-radius: 9px;
    padding: 5px;
    overflow: hidden;
    background-color: white;
}

.requestAssistance_notes__1Nr35 {
    min-height: 100px;
}

.requestAssistance_notes__1Nr35 textarea {
    padding: 5px 5px 5px 20px;
    border: none;
    flex-grow: 1;
}

.requestAssistance_option__1ZhQd {
    display: flex;
    flex-direction: row;
}

.requestAssistance_option__1ZhQd input {
    border: none;
    font-size: 1rem;
    width: 35%;
    padding-left: 20px;
    flex-grow: 1;
}

.requestAssistance_option__1ZhQd select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0px;
    text-overflow: '';
    padding-right: 3px;
    border: none;
    font-size: 1rem;
    width: 30%;
    background: white;
    color: #1a1a1a;
    direction: ltr;
    flex-grow: 1;
}

.requestAssistance_option__1ZhQd select:active {
    direction: ltr;
}

.requestAssistance_option__1ZhQd label {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0px;
    text-overflow: '';
    border: none;
    background: white;
    color: #1a1a1a;
    direction: ltr;
    margin: 0;
    padding: 5px;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    min-width: 50px;
}

.requestAssistance_option__1ZhQd textarea {
    direction: ltr;
    margin: 0;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    min-height: 100px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

hr {
    width: unset;
    margin: 0px 5px;
    color: #f1f0f5;
    border: solid 1px;
    margin: 5px;
}

.requestAssistance_title__2dr56 {
    text-align: center;
    padding: 20px;
}

.requestAssistance_submitButton__2UPmK {
    align-self: center;
    margin: 30px 0px 0px;
    width: 100%;
}

.requestAssistance_addressContainer__tn8kV {
    display: flex;
    flex-direction: row;
    align-self: center;
}

.requestAssistance_address__3_6cO {
    padding: 0 0 10px;
    font-size: 1rem;
}

.requestAssistance_preSendHTML__1vbo3 {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-content: center;
}

.requestAssistance_explination__2wwAz {
    font-size: 0.8rem;
}
.queryError_detailsContainer__1aGin {
    padding: 50px 10px 40px;
    text-align: center;
}

.queryError_title__aUzuB {
    padding: 10px 0px;
}

.queryError_details__1rAhf {
    padding: 0px 10px;
}
.modal_backdrop__4B6h5 {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    opacity: 0;
    transition: opacity 0.8s;
}

.modal_modal__2JcZ7 {
    position: fixed;
    width: 90vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    top: 0;
    margin-top: 80px;
    padding: 15px;
    border-radius: 20px;
    max-width: 400px;
    background-color: #f1f0f5;
    transition: opacity 0.8s;
    transition: all 0.8s;
    opacity: 0;
}

.modal_enable__2xANB {
    display: flex;
    flex-direction: column;
    opacity: 1;
    transition: opacity 0.8s;
}

.modal_close__2SM14 {
    position: absolute;
    right: 0;
    margin-right: 20px;
    font-size: 1rem;
    cursor: pointer;
}

.modal_fadeIn__2Stiz {
    -webkit-animation-name: modal_fadeInAnimation__3sQVt;
            animation-name: modal_fadeInAnimation__3sQVt;
    -webkit-animation-duration: 0.8s;
            animation-duration: 0.8s;
    transition: 0.8s;
    opacity: 1;
}

@-webkit-keyframes modal_fadeInAnimation__3sQVt {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes modal_fadeInAnimation__3sQVt {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
