.developmentText {
    display: flex;
    align-self: center;
    text-align: center;
    position: fixed;
    z-index: 100;
    color: darkorange;
    font-weight: bolder;
    padding: 2px 4px;
    cursor: default;
    pointer-events: none;
    background-color: #18222dd1;
    border-radius: 0px 0px 5px 5px;
}