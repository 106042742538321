input.enableEdit {
    color: #007aff;
}

input.disableEdit {
    color: #1a1a1a;
    background: white;
    pointer-events: none;
    user-select: none;
}

select.enableEdit {
    color: #007aff;
}

select.disableEdit {
    color: #1a1a1a;
    pointer-events: none;
    user-select: none;
    background: white;
}