.masterContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: flex-end;
    margin-top: 80px;
    padding: 40px 40px 20px;
    min-height: 100px;
    background-color: #18222d;
    color: #f1f0f5;
}