@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster');

* {
  box-sizing: border-box;
}

html {
  font-family: 'Noto Sans JP', sans-serif;
}

body {
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  color: #4D4D4D;
  font-size: 68%;
  padding: none;
  background-color: #f1f0f5;
  color: #1a1a1a;
}

#backdrop-root {
  z-index: 10;
  display: flex;
  justify-content: center;
}

#root {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

button {
  padding: 15px 40px;
  margin: 10px 0px 30px;
  border: none;
  border-radius: 8px;
  background-color: #18222d;
  font-size: 15px;
  color: white;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.5s;
}

button:disabled {
  opacity: 0.5;
  transition: opacity 0.5s;
  pointer-events: none;
  cursor: default;
}

.editButton {
  padding: 0;
  color: #007aff;
  font-size: 15px;
  margin-bottom: 5px;
  cursor: pointer;
}

.zeroOpacity {
  opacity: 0;
  transition: opacity 2s;
}

.remove {
  user-select: none !important;
  pointer-events: none ! important;
  display: none !important;
}

.primaryButton {
  margin-bottom: 20px;
  background-color: #18222d;
  color: white;
  transition: all 0.2s;
}

.primaryButton:hover {
  box-shadow: inset 0px 0px 0px 2px #18222d;
  background-color: transparent;
  color: #18222d;
}

.secondaryButton {
  box-shadow: inset 0px 0px 0px 2px #18222d;
  border-radius: 5px;
  background-color: transparent;
  color: #18222d;
  transition: all 0.2s;
}

.secondaryButton:hover {
  background-color: #676e75;
  color: white;
  box-shadow: none;
}